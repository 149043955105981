// ForgotPassword.js
import React, { useState } from 'react';
import { Form, Input, Button, Card, Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { forgotPass } from '../../../../services/apiCalls';

const { Title, Text } = Typography;

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const onFinish = async (values) => {
    setLoading(true);
    try {
      // Call the backend API to send reset password email
      const response = await forgotPass({ email: values.email });

      
      if (response?.data?.success) {
        
        message.success({
          content: 'Password reset link has been sent to your email.',
          style: {
            position: 'absolute',
            right: '0px',
            top: '0px',
          }
        });
        navigate('/resetPassword?email=' + values.email);
      } else {
        message.error({
          content: 'User is not available',
          style: {
            position: 'absolute',
            right: '0px',
            top: '0px',
          }
        })
      }
    } catch (error) {

      message.error({
        content: error?.response?.data?.message,
        style: {
          position: 'absolute',
          right: '0px',
          top: '0px',
        }
      })
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Card style={{ maxWidth: 400, width: '100%', padding: '20px' }}>
        <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>Forgot Password</Title>
        <Text type="secondary" style={{ display: 'block', marginBottom: '20px', textAlign: 'center' }}>
          Enter your email address to receive a password reset link.
        </Text>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Email Address"
            name="email"
            rules={[{ required: true, message: 'Please input your email!' }, { type: 'email', message: 'Please enter a valid email!' }]}
          >
            <Input size="large" placeholder="Enter your email" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              loading={loading}
              style={{ fontWeight: 'bold' }}
            >
              Send Reset Link
            </Button>
          </Form.Item>
        </Form>
        <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
          Remember your password? <span style={{color:"#1890ff",cursor:"pointer"}} onClick={()=>navigate("/login")}>Login</span>
        </Text>
      </Card>
    </div>
  );
};

export default ForgotPassword;
